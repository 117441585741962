<template>
  <v-app light>
    <v-main color="#f0f0f0">
      <div class="my-6 mx-8 pa-0">
        <v-card class="d-flex flex-column" elevation="0">
          <v-card
            color="primary"
            class="pa-4 d-flex flex-row rounded-b-0"
            elevation="0"
            dark
          >
            <div
              class="d-flex flex-row justify-space-between align-center"
              style="width: 100%"
            >
              <h3>
                <v-icon small>mdi-application-cog</v-icon>
                ตั้งค่าการแสดงจุดเริ่มต้นของชั้นข้อมูล
              </h3>
              <v-btn
                to="/msp/muc/matrix"
                target="_blank"
                color="warning"
                large
                elevation="0"
                >ไปยังหน้าวิเคราะห์ MUC</v-btn
              >
            </div>
          </v-card>

          <v-row align="center" justify="end" class="pa-6">
            <v-btn @click="dialogEditPlace = true" tile color="success">
              <v-icon left> mdi-plus </v-icon>
              เพิ่มจุดเริ่มต้นชั้นข้อมูล
            </v-btn>
          </v-row>

          <v-row class="pa-6">
            <v-col cols="12" sm="12" md="12">
              <div>
                <v-data-table :headers="headers" :items="placeList">
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                  </template>
                  <template v-slot:item.GeoCode="{ item }">
                    {{ findGeocodeName(item.GeoCode) }}
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <!-- Modal edit place -->
        <v-dialog v-model="dialogEditPlace" max-width="1000px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
              <v-card-title>
                <span>{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" style="height: 400px">
                      <longdo-map @load="initMapEdit" />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.title"
                        label="ชื่อพื้นที่จุดเริ่มต้น"
                        placeholder="เช่น ทะเลอ่าวไทยฝั่งตะวันออก จ.ตราด"
                        :rules="[
                          () => !!editedItem.title || 'กรุณาระบุชื่อที่ต้องการ',
                          () =>
                            (!!editedItem.title && editedItem.title.length <= 30) ||
                            'ชื่อพื้นที่ต้องมีความยาวไม่เกิน 30 ตัวอักษร',
                        ]"
                        required
                        :counter="30"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.description"
                        label="คำอธิบายจุดเริ่มต้น"
                        placeholder="เช่น ชั้นข้อมูลที่อยู่ในบริเวณทะเลอ่าวไทยและฝั่งตะวันออก"
                        :rules="[
                          () => !!editedItem.description || 'กรุณาระบุคำอธิบายที่ต้องการ',
                          () =>
                            (!!editedItem.description &&
                              editedItem.description.length <= 50) ||
                            'คำอธิบายต้องมีความยาวไม่เกิน 50 ตัวอักษร',
                        ]"
                        required
                        :counter="50"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.Lat"
                        label="latitude"
                        :rules="[() => !!editedItem.Lat || 'latitude is required']"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.Lon"
                        label="longitude"
                        :rules="[() => !!editedItem.Lon || 'longitude is required']"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.zoom"
                        label="Zoom"
                        placeholder="เลือกได้ตั้งแต่ 1-20"
                        :rules="[() => !!editedItem.zoom || 'Zoom is required']"
                        required
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        :items="provinceList"
                        v-model="editedItem.GeoCode"
                        :rules="[
                          () => editedItem.GeoCode.length > 0 || 'This field is required',
                        ]"
                        label="จังหวัดเริ่มต้น"
                        item-text="name"
                        item-value="value"
                        required
                        multiple
                        hide-details
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialogEditPlace">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveEditItem"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <!-- Modal delete confirm -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title>คุณยืนยันที่จะลบสถานที่เริ่มต้นนี้ ใช่หรือไม่ ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">ยกเลิก</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">ยืนยัน</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- snackbar -->
        <v-snackbar v-model="snackbar" :timeout="timeout" color="success" dark>
          {{ textSuccess }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import provinceList from "@/assets/muc/province.json";
export default {
  name: "MucConflictPlace",
  data: () => ({
    placeList: [],
    provinceList: provinceList,
    dialogEditPlace: false,
    dialogDelete: false,
    max40chars: (v) => v.length <= 40 || "ชื่อยาวเกินไป",
    pagination: {},
    headers: [
      {
        text: "ชื่อ",
        align: "start",
        sortable: false,
        value: "title",
        width: "200px",
      },
      {
        text: "คำอธิบาย",
        sortable: false,
        value: "description",
        width: "200px",
      },
      { text: "latitude", sortable: false, value: "Lat" },
      { text: "longitude", sortable: false, value: "Lon" },
      { text: "zoom", sortable: false, value: "zoom" },
      { text: "geocode", sortable: false, value: "GeoCode", width: "200px" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    map: undefined,
    valid: true,
    editedIndex: -1,
    editedItem: {
      title: "",
      description: "",
      Lat: 13.2,
      Lon: 100.5,
      zoom: 8,
      GeoCode: [],
    },
    defaultItem: {
      title: "",
      description: "",
      Lat: 13.2,
      Lon: 100.5,
      zoom: 8,
      GeoCode: [],
    },
    snackbar: false,
    textSuccess: "บันทึกสำเร็จ",
    timeout: 2000,
  }),
  components: {},
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "เพิ่มสถานที่เริ่มต้นใหม่"
        : "แก้ไขสถานที่จุดเริ่มต้นเดิม";
    },
  },
  created() {},
  mounted() {
    let self = this;
    self.getMucPlaceList();
  },
  methods: {
    initMapEdit(map) {
      let self = this;
      self.map = map;

      setTimeout(() => {
        self.map.resize();
      }, 2000);

      self.map.Event.bind("ready", function () {
        self.map.resize();
        self.map.zoom(self.editedItem.zoom);
        self.map.location({ lat: self.editedItem.Lat, lon: self.editedItem.Lon });
      });

      self.map.Event.bind("drop", function () {
        self.map.resize();
        self.editedItem.Lat = self.map.location().lat;
        self.editedItem.Lon = self.map.location().lon;
      });

      self.map.Event.bind("zoom", function () {
        self.editedItem.zoom = self.map.zoom();
      });
    },
    getMucPlaceList() {
      let self = this;
      let url = process.env.VUE_APP_WEBSERVICE + "/get/mucList";
      self.axios.get(url).then((response) => {
        self.placeList = response.data.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.placeList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEditPlace = true;

      setTimeout(() => {
        this.map.resize();
        this.map.zoom(this.editedItem.zoom);
        this.map.location({ lat: this.editedItem.Lat, lon: this.editedItem.Lon });
      }, 200);
    },
    deleteItem(item) {
      this.editedIndex = this.placeList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let self = this;
      let config = {
        method: "post",
        url: process.env.VUE_APP_WEBSERVICE + "/delete/mucList/" + self.editedItem.ID,
        headers: {
          "Content-Type": "application/json",
        },
      };

      self
        .axios(config)
        .then(function () {
          self.getMucPlaceList();
          self.closeDelete();
        })
        .catch(function (error) {
          console.log(error);
          self.closeDelete();
        });
    },
    closeDialogEditPlace() {
      this.dialogEditPlace = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveEditItem() {
      let self = this;
      let idLayer = "";
      if ("ID" in self.editedItem) {
        idLayer = self.editedItem.ID;
      }
      if (self.$refs.form.validate()) {
        let config = {
          method: "post",
          url: process.env.VUE_APP_WEBSERVICE + "/set/mucList/" + idLayer,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            title: self.editedItem.title,
            description: self.editedItem.description,
            geocode: self.editedItem.GeoCode,
            lat: self.editedItem.Lat,
            lon: self.editedItem.Lon,
            zoom: self.editedItem.zoom,
          }),
        };

        console.log(config);

        self
          .axios(config)
          .then(function () {
            self.snackbar = true;
            self.getMucPlaceList();
            self.closeDialogEditPlace();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        return 0;
      }
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.placeList[this.editedIndex], this.editedItem);
      } else {
        this.placeList.push(this.editedItem);
      }
      this.closeDialogEditPlace();
    },
    findGeocodeName(val) {
      let self = this;
      let nameList = "";

      for (let i = 0; i < val.length; i++) {
        nameList += "," + self.provinceList.find((obj) => obj.value === val[i]).name;
      }

      return nameList.slice(1);
    },
  },
  watch: {
    dialogEditPlace(val) {
      val || this.closeDialogEditPlace();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
<style scoped></style>
